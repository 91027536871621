import { useAddonState } from '@storybook/api';
import { IconButton } from '@storybook/components';
import { addons } from '@storybook/addons';
import { create, themes } from '@storybook/theming';
import { ADDON_ID, EVENTS } from '../../constants';
import brandImageLight from './storybook-logo-light.svg';
import brandImageDark from './storybook-logo-dark.svg';

import React from 'react';

const MoonIcon = () => (
    <svg viewBox='0 0 24 24' focusable='false'>
        <path
            fill='currentColor'
            d='M21.4,13.7C20.6,13.9,19.8,14,19,14c-5,0-9-4-9-9c0-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0-0.7-0.3-1 c-0.3-0.3-0.6-0.4-1-0.3C4.3,2.7,1,7.1,1,12c0,6.1,4.9,11,11,11c4.9,0,9.3-3.3,10.6-8.1c0.1-0.3,0-0.7-0.3-1 C22.1,13.7,21.7,13.6,21.4,13.7z'
        ></path>
    </svg>
);

const SunIcon = () => (
    <svg viewBox='0 0 24 24' focusable='false'>
        <g strokeLinejoin='round' strokeLinecap='round' strokeWidth='2' fill='none' stroke='currentColor'>
            <circle cx='12' cy='12' r='5'></circle>
            <path d='M12 1v2'></path>
            <path d='M12 21v2'></path>
            <path d='M4.22 4.22l1.42 1.42'></path>
            <path d='M18.36 18.36l1.42 1.42'></path>
            <path d='M1 12h2'></path>
            <path d='M21 12h2'></path>
            <path d='M4.22 19.78l1.42-1.42'></path>
            <path d='M18.36 5.64l1.42-1.42'></path>
        </g>
    </svg>
);

const BrandLight = () => (
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 80'>
        <g>
            <path fill='#00BCD4' d='M59.8,27.2v-6.5h6.5v6.5H59.8z' />
            <g>
                <path
                    fill='#121417'
                    d='M92.6,37.6v13.2h-6.5v-11c0-2.3-1.7-4.1-3.9-4.3c-0.1,0-0.3,0-0.4,0h0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0
          c-1.8,0.1-3.3,1.3-3.8,2.9c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.4-0.1,0.6v11.1H71V30.2h6.5v3.4
          c0,0,0,0,0-0.1c0.7-1.2,1.7-2.2,2.9-2.8c1.1-0.6,2.2-0.9,3.5-1c0.2,0,0.5,0,0.8,0c1.5,0,2.9,0.4,4,1.1c1,0.6,1.9,1.4,2.5,2.4
          c0.1,0.1,0.2,0.3,0.3,0.5C92.3,34.9,92.6,36.2,92.6,37.6L92.6,37.6z'
                />
                <path
                    fill='#121417'
                    d='M38,30.2v1.4c-0.5-0.4-1-0.7-1.6-1c-1.1-0.5-2.4-0.8-3.8-0.8c-1.9,0-3.6,0.4-5,1.3c-1.5,0.9-2.6,2.1-3.5,3.6
          c-0.8,1.5-1.3,3.3-1.3,5.2c0,1.9,0.4,3.6,1.3,5.2c0.8,1.6,2,2.8,3.5,3.7c1.5,0.9,3.1,1.4,5,1.4c1.4,0,2.6-0.3,3.8-0.8
          c0.5-0.3,1-0.6,1.5-0.9v1.3c0,1.4-0.5,2.6-1.4,3.3c-0.9,0.8-2.2,1.2-3.8,1.2c-1.3,0-2.4-0.2-3.3-0.7c-0.9-0.5-1.7-1.1-2.5-2l-4,4
          c1,1.4,2.3,2.5,4,3.3c1.7,0.8,3.7,1.2,6,1.2c2.3,0,4.2-0.4,5.9-1.3c1.7-0.9,3.1-2.1,4.1-3.6c1-1.6,1.5-3.4,1.5-5.5V30.2H38
          L38,30.2z M36.2,43.8c-0.7,0.4-1.4,0.6-2.4,0.6c-0.9,0-1.7-0.2-2.4-0.6c-0.7-0.4-1.2-0.9-1.5-1.6c-0.4-0.7-0.6-1.4-0.6-2.2
          c0-0.8,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.6-1.6c0.7-0.4,1.5-0.6,2.3-0.6c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.2,0.9,1.5,1.6
          c0.4,0.7,0.5,1.4,0.5,2.3c0,0.8-0.2,1.6-0.5,2.3C37.4,42.8,36.9,43.4,36.2,43.8z'
                />
                <path fill='#121417' d='M48.9,50.8V20h6.5v30.8H48.9z' />
                <path fill='#121417' d='M59.8,50.8V30.2h6.5v20.6H59.8z' />
                <path
                    fill='#121417'
                    d='M110.8,30.1v1.4c-0.5-0.4-1-0.7-1.6-1c-1.1-0.5-2.4-0.8-3.8-0.8c-1.9,0-3.6,0.4-5,1.3
          c-1.5,0.9-2.6,2.1-3.5,3.6c-0.8,1.5-1.3,3.3-1.3,5.2c0,1.9,0.4,3.6,1.3,5.2c0.8,1.6,2,2.8,3.5,3.7c1.5,0.9,3.1,1.4,5,1.4
          c1.4,0,2.6-0.3,3.8-0.8c0.5-0.3,1-0.6,1.5-0.9v1.3c0,1.4-0.5,2.6-1.4,3.3c-0.9,0.8-2.2,1.2-3.8,1.2c-1.3,0-2.4-0.2-3.3-0.7
          s-1.7-1.1-2.5-2l-4,4c1,1.4,2.3,2.5,4,3.3c1.7,0.8,3.7,1.2,6,1.2c2.3,0,4.2-0.4,5.9-1.3c1.7-0.9,3.1-2.1,4.1-3.6
          c1-1.6,1.5-3.4,1.5-5.5V30.1H110.8L110.8,30.1z M109,43.7c-0.7,0.4-1.4,0.6-2.4,0.6c-0.9,0-1.7-0.2-2.4-0.6
          c-0.7-0.4-1.2-0.9-1.5-1.6c-0.4-0.7-0.6-1.4-0.6-2.2c0-0.8,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.6-1.6c0.7-0.4,1.5-0.6,2.3-0.6
          c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.2,0.9,1.5,1.6c0.4,0.7,0.5,1.4,0.5,2.3c0,0.8-0.2,1.6-0.5,2.3C110.2,42.8,109.7,43.3,109,43.7z'
                />
            </g>
        </g>
    </svg>
);

/**
 * This component is rendered in the Storybook toolbar
 */
export const ColorModeTool = () => {
    const isDarkMode = localStorage.getItem('gds-color-mode') === 'dark';
    const [darkMode, setDarkMode] = useAddonState(`${ADDON_ID}/dark-mode`, isDarkMode);

    const channel = addons.getChannel();

    const brandTheme = {
        colorPrimary: '#00BCD4',
        colorSecondary: '#005F64',
        fontBase: '"Spoqa Han Sans Neo", sans-serif',
    };

    const lightTheme = create({
        ...themes['light'],
        ...brandTheme,
        appBg: '#F8F9FA',
        appContentBg: '#FFFFFF',
        brandImage: brandImageLight,
    });

    const darkTheme = create({
        ...themes['dark'],
        ...brandTheme,
        appBg: '#212529',
        appContentBg: '#121417',
        brandImage: brandImageDark,
    });

    const toggleDarkMode = () => {
        // Storybook
        addons.setConfig({ theme: darkMode ? lightTheme : darkTheme });

        // GDS
        channel.emit(EVENTS.TOGGLE_COLOR_MODE, !darkMode ? 'dark' : 'light');
        setDarkMode(!darkMode);
    };

    return (
        <IconButton
            active={darkMode}
            title={`Set color mode to ${darkMode ? 'light' : 'dark'}`}
            onClick={toggleDarkMode}
        >
            {darkMode ? <SunIcon /> : <MoonIcon />}
        </IconButton>
    );
};
